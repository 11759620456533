<template>
	<div
		id="app"
		:class="[
			{
				'menu-is-visible': navIsOpen,
				'search-is-visible': searchIsOpen,
			},
			bodyClass,
		]"
	>
		<BaseNav />
		<router-view />
	</div>
</template>

<script>
import BaseNav from "@/components/BaseNav.vue";

export default {
	components: {
		BaseNav,
	},

	metaInfo() {
		return {
			meta: [
				{
					vmid: "description",
					name: "description",
					content:
						"What cocktail recipes can I make with the ingredients in my bar? Search over 500 recipes by spirit, modifier, style, and more!",
				},
			],
			title: "Search Cocktail Recipes by Spirit, Style, & More",
			titleTemplate: '%s | Find Your Next Drink'
		};
	},

	computed: {
		bodyClass() {
			return this.$route.meta.bodyClass;
		},

		navIsOpen() {
			return this.$store.getters.getNavMenuFocus;
		},

		searchIsOpen() {
			return this.$store.getters.getSearchMenuFocus;
		},
	},
};
</script>

<style lang="scss">
// $text-color: #050505;
// $link-color: #1B9AAA; // 0D3B66
// $button-color: #0D3B66;
// $ui-medium: #DDDBCB;
$ui-light: #edf4f9;
$ui-text: #2e282a;
$ui-accent: #ef3e36;
$ui-highlight: #f49d37;
$gutterSize: 16px;
$borderRadius: 4px;

html,
body,
#app {
	height: 100%;
}

a {
	color: $ui-accent;

	&:hover {
		text-decoration: none;
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $ui-text;
	font: 300 16px/1.2 "Rubik", sans-serif;
	margin: 0;
}

button {
	cursor: pointer;
}

h1 {
	font-size: 24px;
}

h2 {
	font-size: 20px;
}

h3 {
	font-size: 16px;
}

h4 {
	font-size: 12px;
}

header {
	text-align: center;

	p {
		margin: 0 0 30px;
		opacity: 0.8;
	}
}

#nav {
	padding: $gutterSize;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

// homepage
.home {
	background: url("assets/images/bg-home-large.jpg") bottom right / cover
		no-repeat;
	height: 100%;

	&::before {
		background: $ui-accent;
		color: white;
		content: "Beta";
		font-size: 12px;
		font-weight: 500;
		left: -25px;
		padding: 2px 4px;
		position: fixed;
		text-align: center;
		text-transform: uppercase;
		top: 15px;
		transform: rotate(-45deg);
		width: 100px;
	}

	&.search-is-visible {
		header h1 {
			font-size: 28px;
			margin: 0 0 30px;
		}

		header p {
			margin-bottom: 0;
			max-height: 0;
			opacity: 0;
		}

		.app-menu ~ * {
			filter: none;
		}
	}

	footer {
		bottom: 16px;
		left: 0;
		position: absolute;
		right: 0;
		top: auto;
	}
}

// show search and menu overlays
.menu-is-visible,
.search-is-visible {
	.app-menu__overlay {
		opacity: 0.67;
		pointer-events: visible;
	}

	.app-menu ~ *,
	.app-menu__actions {
		filter: blur(3px);
	}
}

.menu-is-visible {
	nav {
		opacity: 1;
		pointer-events: all;
		right: 0;
	}
}

.search-is-visible {
	.ais-InstantSearch {
		opacity: 1;
		pointer-events: all;
		top: 8px;
	}
}

.wrap {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 600px;
	padding: 0 $gutterSize;
	width: 100%;

	&--med {
		padding: 0 16px;
	}
}

.app-menu + .wrap {
	position: relative;
	top: 65px; // height of abs pos header
	z-index: 7;
}

.text-md {
	li,
	p {
		font-size: 14px;
	}
}
</style>
