<template>
	<div>
		<BaseLoader v-if="loading" />
		<BaseError v-else-if="error" />
		<div v-else id="recipe">
			<section class="wrap">
				<div class="recipe__title">
					<img
						:alt="
							'View of the finished ' +
								recipe.name +
								' cocktail in glass with garnish'
						"
						:src="`${publicPath}images/${slug}-lg@2x.jpg`"
						width="200"
						@error="loadDefaultImage"
					/>
					<h1>
						{{ recipe.name }}
						<button
							v-if="getUser.loggedIn"
							@click="addOrRemoveFavoriteDrink"
							:class="{ saving: savingFave }"
						>
							<RecipeFaveHeart
								v-if="recipeHistory && recipeHistory.isFavorite"
								title="Remove drink from your favorites"
							/>
							<RecipeFaveHeart
								v-else
								fill="lightgray"
								title="Add drink to your favorites"
							/>
						</button>
					</h1>
					<p>{{ recipe.source }}</p>
					<!-- <RecipeRating
						v-if="drinkSaved && newRecipeRating"
						:value="newRecipeRating"
						fill="#F49D37"
					/>
					<RecipeRating
						v-else-if="getUser.loggedIn && recipeStars"
						:value="recipeStars"
						fill="#F49D37"
					/> -->
				</div>

				<div class="recipe__ingredients">
					<table v-if="recipe.ingredients">
						<tr
							v-for="(ingred, key) in prettifyIngredients"
							:key="key"
						>
							<td>
								<span v-if="ingred.amount">
									{{ ingred.amount }}
								</span>
								{{ ingred.unit }}
							</td>
							<td>
								{{ ingred.name }}
							</td>
						</tr>
					</table>
					<img
						:alt="recipe.glass + ' glass'"
						:src="
							require('@/assets/images/glasses/glass-' + recipe.glass + '.svg')
						"
						:title="'Best served in a ' + recipe.glass + ' glass'"
						width="32"
					/>
				</div>

				<section v-if="recipe.preparation" class="recipe__prep">
					<h3>Preparation</h3>
					<ol>
						<li v-for="(step, key) in recipe.preparation" :key="key">
							{{ step }}
						</li>
					</ol>
				</section>
				
				<section v-if="recipe.garnish" class="recipe__garnish">
					<h3>Garnish</h3>
					<p>{{ recipe.garnish }}</p>
				</section>

				<section v-if="recipe.notes" class="recipe__notes">
					<h3>Notes</h3>
					<ul>
						<li v-for="(note, key) in recipe.notes" :key="key">
							<span v-html="note"></span>
						</li>
					</ul>
				</section>
			</section>
			<footer>
				<div class="vertical" v-if="currentTime">
					<h3>{{ randomToast }}!</h3>
					<template v-if="drinkSaved">
						<p>Thanks for your rating!</p>
						<div>
							<RecipeRating
								:value="newRecipeRating"
								fill="white"
							/>
							<BaseButton
								@click.native="undoSaveDrink"
								class="error-text"
								:showSpinner="waiting"
								text="Undo?"
							/>
						</div>
					</template>
					<template v-else>
						<p>Nice work, bartender! Care to rate this drink?</p>
						<div>
							<RecipeRating
								v-model="newRecipeRating"
								fill="white"
							/>
							<BaseButton
								@click.native="saveDrinkToHistory"
								:showSpinner="waiting"
								:disableOnSuccess="drinkSaved"
								text="Submit"
							/>
						</div>
					</template>
				</div>
				<div v-else-if="getUser.loggedIn">
					<p v-if="recipeHistory !== null && 'history' in recipeHistory">
						You&rsquo;ve made this drink {{ recipeMadeCount }}<br />
						You last made it {{ recipeLastMade }}
					</p>
					<p v-else>You&rsquo;ve never made this drink.</p>
					<BaseButton
						@click.native="setCurrentTime"
						:showSpinner="waiting"
						text="I Made It"
					/>
				</div>
				<div v-else style="justify-content: center;">
					<p>💖 This drink has {{ recipeFaveCount }} 🥰</p>
				</div>
			</footer>
		</div>
	</div>
</template>

<script>
import { FB, DB } from "@/firebase/index";
import BaseButton from "@/components/BaseButton.vue";
import BaseError from "@/components/BaseError.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import RecipeFaveHeart from "@/components/RecipeFaveHeart.vue";
import RecipeRating from "@/components/RecipeRating.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		BaseButton,
		BaseError,
		BaseLoader,
		RecipeFaveHeart,
		RecipeRating,
	},

	props: {
		slug: {
			type: String,
			required: true,
		},
	},

	metaInfo () {
		return {
			title: this.pageTitle,
			meta: [
				{ 
					vmid: "description",
					name: "description",
					content: this.pageDescription
				},
				{
					vmid: "og:url",
					property: "og:url",
					content: `${this.publicPath}recipe/${this.slug}`
				},
				{
					vmid: "og:type",
					property: "og:type",
					content: "website"
				},
				{
					vmid: "og:title",
					property: "og:title",
					content: this.pageTitle
				},
				{
					vmid: "og:image",
					property: "og:image",
					content: `${this.publicPath}images/${this.slug}-lg@2x.jpg`
				},
				{
					vmid: "og:description",
					property: "og:description",
					content: this.pageDescription
				},
			]
		}
	},

	data() {
		return {
			currentTime: null,
			defaultImage: require("@/assets/images/default@2x.png"),
			drinkSaved: false,
			error: null,
			loading: true,
			newRecipeRating: 0,
			publicPath: process.env.BASE_URL,
			recipe: null,
			recipeHistory: null,
			savingFave: false,
			userDrinkDocPath: null,
			userDrinkDocData: null,
			waiting: false,
		};
	},

	computed: {
		...mapGetters(["getUser"]),

		firstThreeIngredients() {
			let firstIngreds = [];

			this.recipe.ingredients.forEach((e) => {
				firstIngreds.push(e.name);
			});

			return firstIngreds.slice(0,3);
		},

		pageTitle() {
			return this.recipe !== null ? "How to Make the \"" + this.recipe.name + "\" Cocktail" : "Loading recipe..."
		},
		
		pageDescription() {
			return this.recipe !== null ? "Learn how to make the " + this.recipe.name + " cocktail. Recipe ingredients, step-by-step instructions, garnish, and more." : ""
		},

		prettifyIngredients() {
			this.recipe.ingredients.forEach((e) => {
				if (e.amount !== undefined) {
					e.amount = this.prettifyAmount(e.amount);
				}
			});

			return this.recipe.ingredients;
		},

		randomToast() {
			let toasts = [
				"Cheers",
				"¡Salud",
				"Santé",
				"Skål",
				"Cin cin",
				"Şerefe",
				"Prost",
				"Sláinte",
				"Kanpai",
			];
			let random = Math.floor(Math.random() * toasts.length);
			return toasts[random];
		},

		recipeLastMade() {
			let lastMadeData = this.recipeHistory.history;
			let lastMadeTimes = [];

			lastMadeData.forEach((e) => {
				lastMadeTimes.push(e.date.seconds);
			});

			lastMadeTimes.sort();

			let mostRecentDate = new Date(
				lastMadeTimes[lastMadeTimes.length - 1] * 1000
			).getTime();
			let daysSince = Math.round(
				(Date.now() - mostRecentDate) / (1000 * 3600 * 24)
			);

			if (daysSince > 360) {
				return "over a year ago";
			} else if (daysSince > 27) {
				return Math.round(daysSince / 30) === 1
					? "1 month ago"
					: Math.round(daysSince / 30) + " months ago";
			} else if (daysSince > 13) {
				return Math.round(daysSince / 7) + " weeks ago";
			} else {
				if (daysSince === 7) {
					return "1 week ago";
				} else if (daysSince < 1) {
					return "today";
				} else if (daysSince < 2) {
					return "yesterday";
				} else {
					return daysSince + " days ago";
				}
			}
		},

		recipeFaveCount() {
			if ('favoritedBy' in this.recipe) {
				let count = this.recipe.favoritedBy.length;
	
				if (count === 1) {
					return "been favorited once";
				} else if (count === 2) {
					return "been favorited twice";
				} else {
					return "been favorited " + count + " times";
				}
			}

			return "not been favorited yet"
		},

		recipeMadeCount() {
			let count = this.recipeHistory.history.length;

			if (count === 1) {
				return "once";
			} else if (count === 2) {
				return "twice";
			} else {
				return count + " times";
			}
		},

		recipeStars() {
			return this.recipeHistory !== null
				? "rating" in this.recipeHistory
					? this.recipeHistory.rating
					: 0
				: 0;
		},
	},

	methods: {
		addOrRemoveFavoriteDrink() {
			let self = this;
			this.savingFave = true;

			if (this.recipeHistory) {
				// user has prior history with this drink, so update
				this.userDrinkDocPath
					.update({
						ingredients: self.firstThreeIngredients,
						isFavorite: !self.recipeHistory.isFavorite,
						name: self.recipe.name,
						source: self.recipe.source
					})
					.then(function () {
						let firebaseOperation = null;
						if (self.recipeHistory.isFavorite === true) {
							firebaseOperation = FB.FieldValue.arrayRemove(self.getUser.data.userId)
						} else {
							firebaseOperation = FB.FieldValue.arrayUnion(self.getUser.data.userId)
						}

						// add or remove user ID from drink doc so favorite icon can be shown or hidden in instant search results
						DB.collection("recipes")
							.doc(self.slug)
							.update({
								favoritedBy: firebaseOperation,
							})
							.then(function () {
								self.savingFave = false;
								self.recipeHistory.isFavorite =
									!self.recipeHistory.isFavorite;
							})
							.catch(console.error);
					})
					.catch(function (error) {
						console.log(
							"problem saving or removing as favorite",
							error
						);
					});
			} else {
				// user has no prior history with this drink, so create
				let self = this;

				this.userDrinkDocPath
					.set({
						ingredients: self.firstThreeIngredients,
						isFavorite: true,
						name: self.recipe.name,
						source: self.recipe.source
					})
					.then(function () {
						// save user ID to drink doc so favorite icon can be shown in instant search results
						DB.collection("recipes")
							.doc(self.slug)
							.update({
								favoritedBy: FB.FieldValue.arrayUnion(self.getUser.data.userId),
							})
							.then(function () {
								self.savingFave = false;
								self.recipeHistory = { 'isFavorite': true }
							})
							.catch(console.error);
					})
					.catch(console.error);
			}
		},

		loadDefaultImage(event) {
			event.target.src = this.defaultImage;
			event.target.alt = "Missing image";
		},

		loadRecipe() {
			DB.collection("recipes")
				.doc(this.slug)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.recipe = doc.data();

						if (this.getUser.loggedIn) {
							this.loadRecipeHistory();
						}
					} else {
						this.error = "Recipe does not exist";
					}

					document.dispatchEvent(new Event('page-ready-for-prerender'))

					this.loading = false;
				});
		},

		loadRecipeHistory() {
			// save doc path and doc data (former to be DRY, latter to reduce calls to Firebase)
			this.userDrinkDocPath = DB.collection("users")
				.doc(this.getUser.data.userId)
				.collection("drinks")
				.doc(this.slug);

			this.error = this.recipeHistory = null;

			this.userDrinkDocPath
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.recipeHistory = doc.data();
					} else {
						// console.log("No user history for this drink!");
					}
				})
				.catch((error) => {
					console.log(
						"Error getting user history for this drink:",
						error
					);
				});
		},

		prettifyAmount(amount) {
			let allowableAmounts = [
				0.125, 0.25, 0.333, 0.375, 0.5, 0.625, 0.666, 0.75, 0.875,
				1.125, 1.25, 1.5,
			];
			let prettyAmounts = [
				"⅛",
				"¼",
				"⅓",
				"⅜",
				"½",
				"⅝",
				"⅔",
				"¾",
				"⅞",
				"1⅛",
				"1¼",
				"1½",
			];

			// number is not a decimal, presumably a string like 2-3
			if (!amount.includes(".")) {
				return amount;
			}

			// number is a fraction
			if (amount % 1 != 0) {
				let integer = Math.trunc(amount);
				let fraction = (amount - Math.floor(amount)).toFixed(3);
				let prettyFraction =
					prettyAmounts[allowableAmounts.indexOf(+fraction)];

				return (
					(integer > 0 ? integer : "") + prettyFraction
				).toString();
			} else {
				return parseInt(amount);
			}
		},

		saveDrinkToHistory() {
			// prevent additional saves if button clicked while data being saved
			if (this.waiting) {
				return;
			}

			let self = this;
			this.waiting = true;

			// user HAS prior history with this drink, so update
			if (this.recipeHistory) {
				this.userDrinkDocPath
					.update({
						history: FB.FieldValue.arrayUnion({
							date: self.currentTime,
							rating: self.newRecipeRating
						})
					})
					.then(function () {
						self.drinkSaved = true;
						self.waiting = false;

						setTimeout(() => {
							window.scrollTo(0, document.body.scrollHeight);
						}, 10);
					})
					.catch(function (error) {
						console.log("problem", error);
					});
			
			// user HAS NO prior history with this drink, so create
			} else {
				let self = this;

				this.userDrinkDocPath
					.set({
						history: FB.FieldValue.arrayUnion({
							date: self.currentTime,
							rating: self.newRecipeRating
						})
					})
					.then(function () {
						self.drinkSaved = true;
						self.waiting = false;
						self.recipeHistory = { 'history': { 'date': self.currentTime, 'rating': self.newRecipeRating } }

						setTimeout(() => {
							window.scrollTo(0, document.body.scrollHeight);
						}, 10);
					})
					.catch(function (error) {
						console.log("problem", error);
					});
			}
		},

		setCurrentTime() {
			this.currentTime = FB.Timestamp.now();
		},

		undoSaveDrink() {
			if (this.waiting) {
				return;
			}

			let self = this;
			this.waiting = true;

			this.userDrinkDocPath
				.update({
					history: FB.FieldValue.arrayRemove({
						date: self.currentTime,
						rating: self.newRecipeRating
					})
				})
				.then(function () {
					self.drinkSaved = false;
					self.waiting = false;
					self.newRecipeRating = 0;
				})
				.catch(function (error) {
					console.log("problem", error);
				});
		},
	},

	watch: {
		$route: "loadRecipe",
	},

	created() {
		this.loadRecipe();
	},
};
</script>

<style lang="scss" scoped>
$borderRadius: 4px;
$gutterSize: 16px;
$unitRightMargin: 6px;
$unitWidth: 75px;

h1 {
	align-items: center;
	display: flex;
	font: bold 24px "Rubik", sans-serif;
	margin: 0;

	button {
		background: none;
		border: 0;
		cursor: pointer;
		display: flex;
		margin-left: 6px;
		margin-right: -30px;
		padding: 0;

		&:hover svg {
			transform: scale(105%)
		}

		&.saving::before {
			animation: spin 1.3s linear infinite;
			background: url("../assets/images/waiting.svg") center / 80% no-repeat;
			content: "";
			height: 24px;
			width: 24px;
		}

		&.saving svg {
			display: none;
		}

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
}

// sticks footer to bottom
#recipe {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
}

.recipe__title {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;

	img {
		background: #edf4f9;
		border-radius: 0 0 100px 100px;
		box-shadow: 0 4px 4px transparentize($color: #2e282a, $amount: 0.75);
		margin-bottom: 16px;
		position: relative;
		height: 200px;
		width: 200px;
	}

	p {
		margin: 0;
		opacity: 0.8;

		+ * {
			margin-top: 8px;
		}
	}
}

.recipe__ingredients {
	border: 1px dashed transparentize($color: #2e282a, $amount: 0.8);
	border-width: 1px 0;
	padding: 20px 0;
	position: relative;

	img {
		cursor: help;
		position: absolute;
		right: 0;
		top: 20px;

		&:hover {
			transform: scale(105%)
		}
	}

	table {
		border-collapse: collapse;
		width: calc(100% - 32px);
	}

	td {
		padding: 0 0 4px 0;

		&:first-child {
			font-weight: 700;
			text-align: right;
			padding-right: 8px;
			vertical-align: top;
			white-space: nowrap;
		}
	}
}

.recipe__prep {
	ol {
		counter-reset: my-awesome-counter;
		list-style: none;
		padding-left: 0;

		li {
			counter-increment: my-awesome-counter;
			display: flex;
			margin-bottom: 1em;

			&::before {
				align-items: center;
				background: #edf4f9;
				border-radius: 50%;
				content: counter(my-awesome-counter);
				display: flex;
				font-size: 12px;
				font-weight: 500;
				height: 20px;
				justify-content: center;
				margin-right: 10px;
				min-width: 20px;
			}
		}
	}
}

.recipe__garnish {
	h3 {
		margin-top: 2em;
	}

	p::first-letter {
		text-transform: capitalize;
	}
} 

.recipe__notes {
	h3 {
		margin-top: 2em;
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			display: flex;
			margin-bottom: 1em;

			&::before {
				background: #edf4f9;
				content: "";
				height: 4px;
				margin: 8px 10px 0 0;
				min-width: 20px;
			}
		}
	}
}

footer {
	align-items: center;
	background: #3470a2;
	color: white;
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin-top: 16px;
	padding: $gutterSize;

	> div {
		align-items: center;
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		margin: auto;
		max-width: 600px;

		&.vertical {
			align-items: flex-start;
			flex-direction: column;
		}

		&.vertical > div {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 1em;
			width: 100%;
		}
	}

	h3 {
		background: url("../assets/images/checkmark-circle.svg") center left no-repeat;
		line-height: 24px;
		margin: 0 0 4px;
		padding-left: 32px;
	}

	p {
		margin: 0;
	}

	.button {
		align-self: center;
		min-width: 128px;
		padding: 0;
	}
}
</style>