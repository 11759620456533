import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { AUTH } from "@/firebase/index";
import store from "./store";
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
	created() {
		AUTH.onAuthStateChanged((user) => {
			if (user) {
				store.dispatch("fetchUser", user);
			} else {
				store.dispatch("fetchUser", null);
			}
		});
	},
}).$mount("#app");
