<template>
	<div class="wrap wrap--med">
		<BaseLoader v-if="loading" />
		<BaseError v-else-if="error" />
		<template v-else>
			<main class="text-md" v-if="Object.keys(userDrinks).length">
				<header>
					<h1>Your Favorite Drinks</h1>
				</header>
				<article 
					v-for="(drink, index) in userDrinks" 
					:key="index"
					:class="{'is-favorite': drink.isFavorite}"
				>
					<router-link :to="'/recipe/' + index">
						<img
							:src="`${publicPath}images/${index}@2x.jpg`"
							height="48"
							width="48"
							@error="loadDefaultImage"
						/>
						<header>
							<h3>
								{{ drink.name }}
							</h3>
							<div class="source-ingreds">
								<span>
									{{ drink.source }}
								</span>
								&bull;
								<span class="ingreds">
									<span v-for="(ingredient, index) in drink.ingredients" :key="index">{{ ingredient }}</span>
								</span>
							</div>
						</header>
					</router-link>
				</article>
			</main>
			<main class="empty-state" v-else>
				<img src="@/assets/images/empty-favorites.svg" width="280">
				<h1>No favorite drinks yet</h1>
				<p>Tap the magnifying glass in the upper left to find your next drink!</p>
			</main>
		</template>
	</div>
</template>

<script>
import BaseError from "@/components/BaseError.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import { AUTH, DB } from "@/firebase/index";
import { mapGetters } from "vuex"

export default {
	components: {
		BaseError,
		BaseLoader,
	},

	metaInfo() {
		return {
			title: "Your Favorite Drinks",
		};
	},

	data() {
		return {
			defaultImage: require("@/assets/images/default@2x.png"),
			error: null,
			loading: true,
			publicPath: process.env.BASE_URL,
			userDrinks: {},
		};
	},

	computed: {
		...mapGetters(['getUser']),

		isSignedIn() {
			return this.getUser.loggedIn
		}
	},

	methods: {
		loadUserDrinks() {
			let self = this;

			this.error = null;
			this.menuIsVisible = false;
			this.searchIsVisible = false;
			this.loading = true;

			DB.collection("users/" + this.getUser.data.userId + "/drinks")
				.where('isFavorite', '==', true)
				.get()
				.then((snapshot) => {
					self.loading = false;
					snapshot.docs.forEach((doc) => {
						self.userDrinks[doc.id] = doc.data();
					})
				})
		},

		loadDefaultImage(event) {
			event.target.src = this.defaultImage;
			event.target.alt = "Missing image";
		},
	},

	watch: {
		isSignedIn(newValue, oldValue) {
			if (newValue === false) {
				this.$router.push({ path: '/' })
			}
		}
	},

	created () {
		AUTH.onAuthStateChanged((user) => {
			if (user) {
				this.loadUserDrinks();
			}
		});
	},
}
</script>

<style lang="scss" scoped>
// TODO: this section is (mostly) duplicated in components/InstantSearch
// the article should be made into a component and loaded here
// and in the aforementioned component
$ui-text: #2E282A;
$ui-highlight: #F49D37;
$borderRadius: 4px;

article {
	position: relative;
	z-index: 9;

	&.is-favorite {
		&::before {
			background: url('../assets/images/favorite.svg');
			background-size: cover;
			content: "";
			height: 16px;
			left: 40px;
			position: absolute;
			top: 0px;
			width: 16px;
		}

		img {
			border-top-right-radius: 8px;
		}
	}

	a {
		align-items: center;
		border-bottom: 1px dashed transparentize($color: $ui-text, $amount: 0.8);
		color: inherit;
		display: flex;
		padding: 8px 0;
		text-decoration: none;
	}

	header {
		color: inherit;
		margin-left: 10px;
		text-align: left;
		white-space: nowrap;
		width: calc(100% - 58px);
	}

	h3 {
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img {
		border-radius: $borderRadius;
	}

	li {
		padding: 0 8px;

		&:last-child article a {
			border-bottom: 0;
		}
	}

	mark {
		background: $ui-highlight;
	}

	ol {
		background: white;
		border-radius: 0 0 $borderRadius $borderRadius;
		box-shadow: 4px 4px 10px $ui-text;
		list-style: none;
		margin: 0;
		overflow: scroll;
		padding: 0;
	}

	.source-ingreds {
		display: block;
		font-size: 12px;
		opacity: 0.75;
		overflow: hidden;
		text-overflow: ellipsis;

		> span:first-child {
			font-weight: 400;
		}
	}

	.ingreds span {
		&::after {
			content: ", ";
		}

		&:last-child::after {
			content: "";
		}
	}
}

.empty-state {
	text-align: center;

	img {
		margin-top: 1em;
	}
}
</style>