import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import AllDrinks from "../views/AllDrinks.vue";
import Article from "../views/Article.vue";
import DrinkHistory from "../views/History.vue";
import Favorites from "../views/Favorites.vue";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import Privacy from "../views/Privacy.vue";
import Recipe from "../views/Recipe.vue";
import Signin from "../views/Signin.vue";
import Stats from "../views/Stats.vue";
import store from "../store";

import { FIREBASE } from "../firebase/index.js";
import VueMeta from 'vue-meta'

Vue.use(VueMeta);
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		// component: () => import('../views/Home.vue') //TODO: this seems cleaner than importing everything above (lazy-loading routes)
		component: Home,
		meta: {
			bodyClass: 'home'
		}
	},
	{
		path: "/about",
		name: "About",
		component: About
	},
	{
		path: "/article/:slug",
		name: "Article",
		component: Article,
		props: true
	},
	{
		path: "/cocktails",
		name: "All Drinks",
		component: AllDrinks,
	},
	{
		path: "/favorites",
		name: "Favorites",
		component: Favorites,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/history",
		name: "History",
		component: DrinkHistory,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: Privacy
	},
	{
		path: "/recipe/:slug",
		name: "Recipe",
		component: Recipe,
		props: true
	},
	{
		path: "/sign-in",
		name: "Sign in",
		component: Signin
	},
	{
		path: "/stats",
		name: "Stats",
		component: Stats,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: "*",
		name: "NotFound",
		component: NotFound
	}
];

const router = new VueRouter({
	base: '/',
	mode: 'history',
	routes,
});

router.beforeEach(async (to, from, next) => {
	// set doc title and menu states
	store.dispatch("setSearchMenuState", false);
	// TODO: test this.$store.dispatch... would not need to import `store` above
	store.dispatch("setNavMenuState", false);

	// check for Firebase auth
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	if (requiresAuth && !await FIREBASE.getCurrentUser()) {
		next('/');
	} else {
		next();
	}
})

export default router;
