<template>
	<div class="about wrap">
		<header>
			<h1>
				About This App
			</h1>
		</header>
		<main class="text-md">
			<p>
				This app is the product of my bartending naïveté.
			</p>
			<p>
				Before I learned a handful of drinks by heart, I was stumped when happy hour came around. What, among the thousands of drinks in existence, could I make with the two or three spirits and juices I had on hand? I resorted to flipping through drink recipe books or searching online for &ldquo;gin and lemon juice drinks,&rdquo; a frustrating and often fruitless experience.
			</p>
			<p>
				I thought how nice it would be if there were a database that would only suggest drinks that could be made with the ingredients I had on hand...
			</p>
			<br>
			<h2>Credits</h2>
			<p>
				<a href="https://akveo.github.io/eva-icons/">App icons</a> by Eva. Type set in <a href="https://fonts.google.com/specimen/Rubik">Rubik</a>.<br>
				<a href="https://thenounproject.com/tinyxl/uploads/">Glassware icons</a> by Erin Standley.<br>
				<a href="https://thenounproject.com/icon/hurricane-cocktail-glass-2230911/">Hurricane cocktail glass icon</a> by Bohdan Burmich.<br>
				Homepage photo by <a href="https://unsplash.com/@knightduong?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Knight Duong</a> on <a href="https://unsplash.com/s/photos/cocktail?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
			</p>
			<p>
				Built with Vue.js and Firebase
			</p>
		</main>
	</div>
</template>

<script>

export default {
	metaInfo() {
		return {
			meta: [
				{
					vmid: "description",
					name: "description",
					content:
						"This app is a solution to a problem many of us have at happy hour: what cocktail can I make from the limited ingredients in my bar? Instead of an aimless online search, type in your available ingredients and \"find your next drink!\"",
				},
			],
			title: "About this App",
		};
	},

	mounted () {
		document.dispatchEvent(new Event('page-ready-for-prerender'));
	},
}
</script>

<style lang="scss" scoped>

</style>