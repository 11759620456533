<template>
	<div class="about wrap">
		<header>
			<h1>Sign In</h1>
		</header>
		<main class="text-md">
			<p>You need to be signed in to view that page!</p>
		</main>
	</div>
</template>

<script>
export default {
	metaInfo() {
		return {
			meta: [
				{
					vmid: "description",
					name: "description",
					content:
						"You must be signed into your Booze Hound account to see this page.",
				},
			],
			title: "Sign In",
		};
	},
};
</script>

<style lang="scss" scoped>
</style>