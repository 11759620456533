<template>
	<div class="wrap wrap--med">
		<BaseLoader v-if="loading" />
		<BaseError v-else-if="error" />
		<template v-else>
			<main class="text-md" v-if="drinkCount">
				<header>
					<h1>Your Drink History ({{ drinkCount }})</h1>
				</header>
				<article 
					v-for="(drink, index) in userDrinkHistoryLastMade" 
					:key="index"
					:class="{ 'is-favorite': drink.isFavorite }"
				>
					<router-link :to="'/recipe/' + drink.slug">
						<img
							:src="`${publicPath}images/${drink.slug}@2x.jpg`"
							height="48"
							width="48"
							@error="loadDefaultImage"
						/>
						<header>
							<div>
								<h3>
									{{ drink.name }}
								</h3>
								<RecipeRating
									v-if="drink.rating"
									:value="drink.rating"
									cssClass="small"
									fill="#F49D37"
								/>
							</div>
							<div class="source-ingreds">
								<span>
									{{ drink.source }}
								</span>
								&bull;
								<span class="date">
									<span>{{ convertDateToRelativeDate(drink.date) }}</span>
								</span>
							</div>
						</header>
					</router-link>
				</article>
			</main>
			<main class="empty-state" v-else>
				<img src="@/assets/images/empty-favorites.svg" width="280" />
				<h1>You haven&rsquo;t made any drinks yet</h1>
				<p>Tap the magnifying glass in the upper left to find and make your first drink!</p>
			</main>
		</template>
	</div>
</template>

<script>
import BaseError from "@/components/BaseError.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import RecipeRating from '@/components/RecipeRating.vue';
import { AUTH, DB } from "@/firebase/index";
import { mapGetters } from "vuex"

export default {
	components: {
		BaseError,
		BaseLoader,
		RecipeRating
	},

	metaInfo() {
		return {
			title: "Your Favorite Drinks",
		};
	},

	data() {
		return {
			defaultImage: require("@/assets/images/default@2x.png"),
			error: null,
			loading: true,
			publicPath: process.env.BASE_URL,
			userDrinkHistory: {},
			userDrinkHistoryLastMade: []
		};
	},

	computed: {
		...mapGetters(['getUser']),

		isSignedIn() {
			return this.getUser.loggedIn
		},

		drinkCount() {
			return Object.keys(this.userDrinkHistoryLastMade).length
		}
	},

	methods: {
		getUserDrinkHistory() {
			let self = this;

			/**
			 * This block loops through all drinks for the current user
			 * and moves the `lastMade` field to a new `history` field
			 */
			// let docRef = DB.collection("users")
			// 	.doc(this.getUser.data.userId)
			// 	.collection("drinks")
			// 	// .doc("french-manhattan");

			// docRef.get().then((querySnapshot) => {
			// 	querySnapshot.forEach((doc) => {
			// 	// if (doc.exists) {
			// 		let docData = doc.data();
			// 		let lastMade = docData.lastMade;
			// 		let rating = docData.rating || null;
			// 		// console.log(lastMade, rating)

			// 		if (lastMade) {
			// 			lastMade.forEach(date => {
			// 				doc.ref.set({
			// 					history : FB.FieldValue.arrayUnion({
			// 						date: date,
			// 						rating: rating
			// 					})
			// 				}, { merge: true })
			// 			});
			// 		}
			// 	})
			// }).catch((error) => {
			// 	console.log("Error getting document:", error);
			// });

			DB.collection("users/" + this.getUser.data.userId + "/drinks")
				.where('history', '!=', [])
				.get()
				.then((snapshot) => {
					self.loading = false;
					snapshot.docs.forEach((doc) => {
						self.userDrinkHistory[doc.id] = doc.data();
					})

					// self.removeAllKeysExceptHistory()
					self.getHistoryForEachDrink()
					self.sortDrinksByLastMadeDate()
				})
		},

		// unused
		removeAllKeysExceptHistory() {
			Object.entries(this.userDrinkHistory).forEach(([drinkSlug, drinkMeta]) => (
				Object.keys(drinkMeta).forEach((k) => k === "history" || delete drinkMeta[k])
			));
		},

		getHistoryForEachDrink() {
			Object.entries(this.userDrinkHistory).forEach(([drinkSlug, drinkMeta]) =>
				Object.entries(drinkMeta.history).forEach((history) => (
					this.userDrinkHistoryLastMade.push({ 
						'name': drinkMeta.name, 
						'slug': drinkSlug,
						'date': history[1].date,
						'source': drinkMeta.source,
						'isFavorite': drinkMeta.isFavorite,
						'rating': history[1].rating
					})
				))
			)
		},

		convertDateToRelativeDate(date) {
			let mostRecentDate = new Date(
				date.seconds * 1000
			).getTime();
			let daysSince = Math.round(
				(Date.now() - mostRecentDate) / (1000 * 3600 * 24)
			);

			if (daysSince > 360) {
				return "over a year ago";
			} else if (daysSince > 27) {
				return Math.round(daysSince / 30) === 1
					? "1 month ago"
					: Math.round(daysSince / 30) + " months ago";
			} else if (daysSince > 13) {
				return Math.round(daysSince / 7) + " weeks ago";
			} else {
				if (daysSince === 7) {
					return "1 week ago";
				} else if (daysSince < 1) {
					return "today";
				} else if (daysSince < 2) {
					return "yesterday";
				} else {
					return daysSince + " days ago";
				}
			}
		},

		sortDrinksByLastMadeDate() {
			this.userDrinkHistoryLastMade.sort((a, b) => (a.date.seconds < b.date.seconds) ? 1 : -1)
		},

		loadDefaultImage(event) {
			event.target.src = this.defaultImage;
			event.target.alt = "Missing image";
		},
	},

	watch: {
		isSignedIn(newValue, oldValue) {
			if (newValue === false) {
				this.$router.push({ path: '/' })
			}
		}
	},

	created() {
		AUTH.onAuthStateChanged((user) => {
			if (user) {
				this.getUserDrinkHistory();
			}
		});
	},
}
</script>

<style lang="scss" scoped>
// TODO: this section is (mostly) duplicated in components/InstantSearch
// the article should be made into a component and loaded here
// and in the aforementioned component
$ui-text: #2e282a;
$ui-highlight: #f49d37;
$borderRadius: 4px;

article {
	position: relative;
	z-index: 9;

	&.is-favorite {
		&::before {
			background: url("../assets/images/favorite.svg");
			background-size: cover;
			content: "";
			height: 16px;
			left: 40px;
			position: absolute;
			top: 0px;
			width: 16px;
		}

		img {
			border-top-right-radius: 8px;
		}
	}

	a {
		align-items: center;
		border-bottom: 1px dashed transparentize($color: $ui-text, $amount: 0.8);
		color: inherit;
		display: flex;
		padding: 8px 0;
		text-decoration: none;
	}

	header {
		color: inherit;
		margin-left: 10px;
		text-align: left;
		white-space: nowrap;
		width: calc(100% - 58px);

		> div {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	h3 {
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img {
		border-radius: $borderRadius;
	}

	li {
		padding: 0 8px;

		&:last-child article a {
			border-bottom: 0;
		}
	}

	mark {
		background: $ui-highlight;
	}

	ol {
		background: white;
		border-radius: 0 0 $borderRadius $borderRadius;
		box-shadow: 4px 4px 10px $ui-text;
		list-style: none;
		margin: 0;
		overflow: scroll;
		padding: 0;
	}

	.source-ingreds {
		display: block;
		font-size: 12px;
		opacity: 0.75;
		overflow: hidden;
		text-overflow: ellipsis;

		> span:first-child {
			font-weight: 400;
		}
	}

	.ingreds span {
		&::after {
			content: ", ";
		}

		&:last-child::after {
			content: "";
		}
	}

	.date span {
		display: inline-block;

		&::first-letter {
			text-transform: capitalize;
		}
	}
}

.empty-state {
	text-align: center;

	img {
		margin-top: 1em;
	}
}

.recipe__rating {
	margin: inherit;

	label {
		height: 16px;
	}
}
</style>