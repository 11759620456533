<template>
	<div class="error-404">
		<img src="@/assets/images/unicorn.svg" alt="unicorn" />
		<div>
			<header>
				<h1>Nothing here but this cute lil&rsquo; unicorn.</h1>
				<p>
					The page you seek is about as real as a horned equine.
				</p>
			</header>
			<router-link to="/">
				<BaseButton text="Back Home" />
			</router-link>
		</div>
	</div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
	components: {
		BaseButton
	},
};
</script>

<style lang="scss" scoped>
.error-404 {
	align-items: center;
	background: white;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	padding: 0 30px;

	> div {
		max-width: 100%;
		text-align: center;
	}

	h1 {
		margin: 30px 0 15px;
	}
}
</style>