<template>
	<div class="spinner-wrap" :class="{ 'in-btn': inButton }">
		<div class="spinner">
			<div class="double-bounce1"></div>
			<div class="double-bounce2"></div>
		</div>
		<p v-if="!inButton">Reticulating splines...</p>
	</div>
</template>

<script>
export default {
	props: {
		inButton: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
p {
	color: #2E282A;
    opacity: 0.8;
}

.spinner {
	height: 40px;
	position: relative;
	width: 40px;
}

.spinner-wrap {
	align-items: center;
	display: flex;
	flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #2E282A;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.in-btn {
	&.spinner-wrap {
		margin: auto;
		position: absolute;
		right: 16px;
	}

	.spinner {
		height: 32px;
		width: 32px;
	}

	.double-bounce1,
	.double-bounce2 {
		background-color: white;
	}
}
</style>
