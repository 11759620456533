<template>
	<div class="app-menu">
		<!-- TODO: move to separate component -->
		<p class="toast" v-if="justSignedIn">
			🥳 Welcome back! You&rsquo;re looking good today. 🔥
		</p>
		<p class="toast" v-if="justSignedOut">
			👋 Ciao! See you at the next happy hour! 🍸
		</p>
		<InstantSearch hasBorder />
		<div @click="hideMenuAndSearch" class="app-menu__overlay" />
		<button 
			v-if="$route.path === '/'" 
			@click="navMenuIsOpen(true)"
			id="floating-avatar" 
			title="Tap to access site navigation"
		>
			<img v-if="getUser.loggedIn" alt="Avatar" :src="getUser.data.photoUrl" />
			<img v-else alt="Avatar" src="@/assets/images/bh-avatar@2x.png" />
		</button>
		<div v-else class="app-menu__actions">
			<button type="button" @click="openSearchBox">Search</button>
			<router-link to="/" exact="" title="Go to the home page">Home</router-link>
			<button type="button" @click="navMenuIsOpen(true)">Menu</button>
		</div>
		<nav>
			<template v-if="getUser.loggedIn">
				<div class="nav__header">
					<img alt="Avatar" :src="getUser.data.photoUrl" />
					<div class="nav__user-info">
						<h3>{{ getUser.data.displayName }}</h3>
						<p>{{ getUser.data.email }}</p>
					</div>
					<button class="close-nav" @click="navMenuIsOpen(false)">
						<BaseIcon icon="arrow-forward-outline" />
						Close
					</button>
				</div>
				<section>
					<h4>Your</h4>
					<router-link to="/favorites">
						<BaseIcon icon="heart-outline" />
						Favorites
					</router-link>
					<router-link to="/history">
						<BaseIcon icon="calendar-outline" />
						History
					</router-link>
					<!-- <router-link to="/stats">
						<BaseIcon icon="bar-chart-outline" />
						Stats
					</router-link> -->
				</section>
			</template>
			<template v-else>
				<div class="nav__header">
					<img alt="Avatar" src="@/assets/images/bh-avatar@2x.png" />
					<div class="nav__user-info">
						<h3>Booze Hound</h3>
						<p>Find Your Next Drink</p>
					</div>
					<button class="close-nav" @click="navMenuIsOpen(false)">
						<BaseIcon icon="arrow-forward-outline" />
						Close
					</button>
				</div>
			</template>
			<section>
				<h4>Explore</h4>
				<!-- <router-link to="/new-cocktails">
					<BaseIcon icon="clock-outline" />
					New Additions
				</router-link> -->
				<router-link to="/cocktails">
					<BaseIcon icon="layers-outline" />
					All Drinks
				</router-link>
			</section>
			<section>
				<h4>More</h4>
				<router-link to="/about">
					<BaseIcon icon="info-outline" />
					About
				</router-link>
				<router-link to="/privacy">
					<BaseIcon icon="eye-off-outline" />
					Privacy
				</router-link>
				<template v-if="getUser.loggedIn">
					<button @click.prevent="signOut">
						<BaseIcon icon="log-out-outline" />
						<span> Sign Out </span>
					</button>
				</template>
				<template v-else>
					<button @click="googleSignIn">
						<BaseIcon icon="log-in-outline" />
						Sign In with Google
					</button>
				</template>
			</section>
			<AppVersion />
		</nav>
	</div>
</template>

<script>
import AppVersion from "@/components/AppVersion.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import InstantSearch from "@/components/InstantSearch.vue";
import { mapGetters } from "vuex";
import firebase from "firebase";
import store from "../store";

export default {
	components: {
		AppVersion,
		BaseIcon,
		InstantSearch,
	},

	computed: {
		...mapGetters(["getUser"]),
	},

	data() {
		return {
			justSignedIn: false,
			justSignedOut: false,
		};
	},

	methods: {
		googleSignIn() {
			let self = this;
			var provider = new firebase.auth.GoogleAuthProvider();
			provider.addScope("profile");
			provider.addScope("email");
			firebase
				.auth()
				.signInWithPopup(provider)
				.then(function (result) {
					store.dispatch("fetchUser", result.user);
					self.showSignInToast();
				});
		},

		hideMenuAndSearch() {
			this.navMenuIsOpen(false);
			this.searchIsOpen(false);
		},

		navMenuIsOpen(state) {
			store.dispatch("setNavMenuState", state);
		},

		openSearchBox() {
			let x = document.getElementsByClassName("ais-SearchBox-input");
			x[0].focus();
			this.searchIsOpen(true);
		},

		searchIsOpen(state) {
			store.dispatch("setSearchMenuState", state);
		},

		showSignInToast() {
			this.justSignedIn = true;
			this.navMenuIsOpen(false);

			setTimeout(() => {
				this.justSignedIn = false;
			}, 5000);
		},

		showSignOutToast() {
			this.justSignedOut = true;
			this.navMenuIsOpen(false);

			setTimeout(() => {
				this.justSignedOut = false;
			}, 5000);
		},

		signOut() {
			firebase
				.auth()
				.signOut()
				.then(() => {
					this.showSignOutToast();
				});
		},
	},
};
</script>

<style lang="scss" scoped>
$gutterSize: 16px;

.app-menu {
	background: #edf4f9;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.app-menu__actions {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 16px;
	margin: auto;

	a {
		background: url("../assets/images/logo.svg") center no-repeat;
		height: 0;
		overflow: hidden;
		padding-top: 32px;
		width: 151px;

		&:hover {
			transform: scale(102%)
		}
	}

	button {
		background: url("../assets/images/search.svg");
		border: 0;
		cursor: pointer;
		height: 0;
		overflow: hidden;
		padding-top: 32px;
		width: 32px;

		&:hover {
			transform: scale(102%);
		}
	}

	button:last-of-type {
		background: url("../assets/images/menu.svg");
		background-size: cover;
	}
}

.app-menu__overlay {
	background: #2e282a;
	bottom: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity 200ms ease-in-out;
	z-index: 8;
}

.ais-InstantSearch {
	left: 8px;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 8px;
	top: -58px;
	transition: opacity 200ms ease-in-out, top 200ms ease-in-out;
	z-index: 9;
}

.ais-Hits-list {
	max-height: calc(100vh - 104px);
}

nav {
	background: white;
	bottom: 0;
	box-shadow: 0 4px 4px transparentize($color: #2e282a, $amount: 0.75);
	max-width: 360px;
	opacity: 0;
	position: fixed;
	right: -76%;
	top: 0;
	transition: opacity 200ms ease-in-out, right 200ms ease-in-out;
	width: 75%;
	z-index: 10;

	a {
		align-items: center;
		color: #2e282a;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		line-height: 32px;
		padding-left: 4px;
		text-decoration: none;

		&:hover svg {
			transform: scale(105%)
		}

		&.router-link-active {
			background: #edf4f9;
			border-radius: 8px;
			// color: #3470A2; // need to colorize SVGs to match
			font-weight: bold;
			margin-left: -4px;
			padding-left: 8px;

			svg {
				transform: scale(105%);
			}
		}
	}

	button {
		border: 0;
	}

	h4 {
		margin: 0 0 4px;
	}

	section {
		margin: 16px 16px 32px;

		button {
			// background: #3470A2;
			// color: white;
			// margin-top: 32px;
			align-items: center;
			background: transparent;
			border-radius: 8px;
			color: #2e282a;
			display: flex;
			line-height: 32px;
			margin-left: -4px;
			padding: 0 0 0 8px;
			width: calc(100% + 4px);

			&:hover svg {
				transform: scale(105%)
			}
		}
	}

	small {
		bottom: 16px;
		position: absolute;
		right: 16px;
	}

	svg {
		margin-right: 12px;
		width: 16px;
		transition: margin 200ms ease-in-out;
	}
}

.nav__header {
	align-items: center;
	background: #edf4f9;
	display: flex;
	padding: 8px 16px;

	button {
		background: none;
		font-size: 0;

		&:hover {
			transform: scale(102%);
		}

		svg {
			margin: 0;
			width: auto;
		}
	}

	h3,
	p {
		margin: 0;
	}

	img {
		border-radius: 8px;
		height: 48px;
		margin-right: 10px;
		width: 48px;
	}

	p {
		font-size: 12px;
	}
}

.nav__user-info {
	flex-grow: 1;
}

// notification toasts
.toast {
	background: #2e282a;
	border-radius: 4px;
	box-shadow: 0 4px 4px transparentize($color: #2e282a, $amount: 0.75);
	color: #edf4f9;
	font-size: 12px;
	font-weight: 400;
	left: $gutterSize;
	margin: auto;
	max-width: 600px;
	opacity: 1;
	padding: 8px;
	position: fixed;
	right: $gutterSize;
	text-align: center;
	top: $gutterSize;
	z-index: 10;
}

#floating-avatar {
	background: none;
	border: 0;
	padding: 0;
	position: fixed;
	right: 16px;
	top: 16px;

	&:hover img {
		box-shadow: 2px 2px 8px transparentize($color: #2e282a, $amount: 0.65);
		transform: translate3d(-1px, -1px, 0);
	}

	img {
		border-radius: 50%;
		box-shadow: 1px 1px 4px transparentize($color: #2e282a, $amount: 0.75);
		display: block;
		transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
		width: 40px;
	}
}
</style>