<template>
	<section>
		<header>
			<h1>Booze<span>Hound</span></h1>
			<p>Search over 500 cocktail recipes.</p>
		</header>
		
		<div class="wrap">
			<InstantSearch />
		</div>
		<footer>
			<div>
				<router-link to="/about">About</router-link>
				<router-link to="/privacy">Privacy</router-link>
			</div>
			<AppVersion />
		</footer>
	</section>
</template>

<script>
import AppVersion from '@/components/AppVersion.vue';
import InstantSearch from '@/components/InstantSearch.vue';
// import { DB } from "@/firebase/index";

export default {
	name: "Home",

	components: {
		AppVersion,
		InstantSearch,
	},

	mounted () {
		document.dispatchEvent(new Event('page-ready-for-prerender'));
	},

	// methods: {
	// 	get() {
	// 		DB.collection("recipes")
	// 			.where("glass", "==", null)
	// 			.get()
	// 			.then((querySnapshot) => {
	// 				querySnapshot.forEach((doc) => {
	// 					// doc.data() is never undefined for query doc snapshots
	// 					console.log(doc.id, " => ", doc.data());
	// 				});
	// 			})
	// 			.catch((error) => {
	// 				console.log("Error getting documents: ", error);
	// 			});
	// 	}
	// },

	// mounted () {
	// 	this.get();
	// },
};
</script>

<style lang="scss" scoped>
$ui-light: #EDF4F9;
$ui-text: #2E282A;
$ui-accent: #ef3e36;
$ui-highlight: #F49D37;
$borderRadius: 4px;
$gutterSize: 16px;

footer {
	display: flex;
	font-size: 14px;
	justify-content: space-between;
	margin: auto;
	max-width: 600px;
	padding: 0 $gutterSize;

	a,
	button {
		color: inherit;
		margin-right: 16px;
		text-decoration: none;
	}

	button {
		background: none;
		font: 300 14px/1.2 "Rubik", sans-serif;
		border: 0;
		padding: 0;
	}

	span {
		opacity: 0.5;
	}
}

header {
	h1 span {
		color: $ui-accent;
		display: block;
	}

	p {
		font-weight: 400;
		max-height: 1.2em;
		overflow: hidden;
		transition: all 200ms ease-in-out;
	}
}

h1 {
	font: 40px/1 'Rubik Mono One', sans-serif;
	margin: 0 0 20px;
	padding-top: 100px;
	transition: font-size 200ms ease-in-out, margin 200ms ease-in-out;
}
</style>