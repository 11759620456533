<template>
	<small> {{ appDate }} &bull; v{{ appVersion }} </small>
</template>

<script>
export default {
	data() {
		return {
			appDate: '16 Feb 2022',
			appVersion: 'β 1.0.7'
		}
	},
};
</script>

<style lang="scss" scoped>
small {
	font-size: 12px;
	opacity: 0.5;
}
</style>