<template>
	<button
		class="button"
		:class="[
			showSpinner
				? 'state waiting'
				: disableOnSuccess
					? 'state success'
					: '',
			btnClass,
		]"
		type="button"
	>
		<span v-if="showSpinner">Wait...</span>
		<span v-else-if="disableOnSuccess">Success!</span>
		<span v-else>{{ text }}</span>
	</button>
</template>

<script>
export default {
	props: {
		btnClass: {
			type: String,
		},
		disableOnSuccess: {
			type: Boolean,
			default: false,
		},
		showSpinner: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			required: true,
			default: "Drink Me",
		},
	},
};
</script>

<style lang="scss" scoped>
$borderRadius: 4px;

.button {
	background: white;
	border: 0;
	border-radius: $borderRadius;
	box-shadow: 0 4px 4px transparentize($color: #2e282a, $amount: 0.75);
	color: #3470a2;
	cursor: pointer;
	font: 500 14px/40px "Rubik", sans-serif;
	padding: 0 36px;

	&.on-light-bg {
		background: #3470a2;
		color: white;
	}

	&.error-text {
		color: #ef3e36;
	}

	&.state {
		display: flex;
		justify-content: center;
		padding: 8px 0;

		&.success {
			box-shadow: none;
			cursor: not-allowed;
			min-width: 0;
			padding: 8px;
		}

		&.waiting span {
			animation: spin 1.3s linear infinite;
			background-image: url("../assets/images/waiting.svg");
		}

		&.success span {
			background-image: url("../assets/images/checkmark.svg");
		}

		span {
			background-position: center !important;
			background-repeat: no-repeat !important;
			display: inline-block;
			height: 0;
			overflow: hidden;
			padding-top: 24px;
			width: 24px;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
</style>