<template>
	<div class="wrap">
		<main class="text-md">
			<img src="@/assets/images/empty-ghost.svg" width="280"/>
			<h1>Nothing here but this spooky lil' ghost.</h1>
			<p>You tried to access a non-existent page.</p>
		</main>
	</div>
</template>

<script>

export default {
	metaInfo() {
		return {
			title: "Page Not Found",
		};
	},
}
</script>

<style lang="scss" scoped>
main {
	text-align: center;

	img {
		margin-top: 1em;
	}
}
</style>