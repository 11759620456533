<template>
	<ais-instant-search
		:class="{ 'has-border': hasBorder }"
		:search-client="searchClient"
		index-name="Cocktails"
	>
		<ais-search-box
			placeholder="Find by name, spirit, ingredients, and more"
		/>

		<ais-state-results>
			<template slot-scope="{ results: { hits }, state: { query } }">
				<ais-stats v-show="query.length">
					<p
						class="search-stats"
						v-if="nbHits > 1"
						slot-scope="{ nbHits }"
					>
						<span>
							<strong>{{ nbHits }}</strong> matching recipes
						</span>
						<img
							alt="Search powered by Algolia"
							src="@/assets/images/algolia-search.svg"
							height="14"
							width="98"
						/>
					</p>
					<p
						class="search-stats"
						v-else-if="nbHits === 1"
						slot-scope="{ nbHits }"
					>
						<span>
							<strong>{{ nbHits }}</strong> matching recipe
						</span>
						<img
							alt="Search powered by Algolia"
							src="@/assets/images/algolia-search.svg"
							height="14"
							width="98"
						/>
					</p>
				</ais-stats>
				<p
					v-show="query.length && !hits.length"
					class="search-stats no-results"
				>
					🥺 I couldn&rsquo;t track that down for you 😭
				</p>
			</template>
		</ais-state-results>

		<div ref="resultsListWrap">
			<ais-infinite-hits :style="{ maxHeight: resultsMaxHeight }">
				<template
					v-slot="{
						items,
						refineNext,
						isLastPage,
					}"
				>
					<ul>
						<li v-for="item in items" :key="item.objectID">
							<article
								:class="{
									'is-favorite':
										getUser.loggedIn &&
										'favoritedBy' in item &&
										item.favoritedBy.includes(getUser.data.userId),
								}"
							>
								<router-link :to="'/recipe/' + item.objectID">
									<img
										:src="`${publicPath}images/${item.thumbnail}`"
										height="48"
										width="48"
										@error="loadDefaultImage"
									/>
									<header>
										<h3>
											<ais-highlight
												:hit="item"
												attribute="name"
											/>
										</h3>
										<div class="source-ingreds">
											<ais-highlight
												:hit="item"
												attribute="source"
											/>
											&bull;
											<span class="ingreds">
												<ais-highlight
													:hit="item"
													:attribute="'ingredients.' + index"
													v-for="(
														ingredient, index
													) in item.ingredients"
													:key="index"
												/>
											</span>
										</div>
									</header>
								</router-link>
							</article>
						</li>
						<li class="show-more-results" v-if="!isLastPage">
							<button @click="refineNext">
								Show More Results
							</button>
						</li>
					</ul>
					
				</template>
			</ais-infinite-hits>
		</div>
	</ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { mapGetters } from "vuex";
import store from "../store";

const algoliaClient = algoliasearch(
	"92X0HWDXN7",
	"76c7ad821382319bd5aeae488c39e65e"
);

/**
 * Only call Algolia once a query is entered into the search box
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/vue/
 */
const searchClient = {
	...algoliaClient,
	search(requests) {
		if (requests.every(({ params }) => !params.query)) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					processingTimeMS: 0,
				})),
			});
		}
		return algoliaClient.search(requests);
		// would this work to get hitsPerPage?
		// see: https://www.algolia.com/doc/api-client/getting-started/upgrade-guides/javascript/#the-search-index
		// index.search('query', {
		// 	hitsPerPage: 50
		// 	// Any other parameter
		// });
	},
};

export default {
	props: {
		hasBorder: {
			type: Boolean,
		},
	},

	data() {
		return {
			defaultImage: require("@/assets/images/default@2x.png"),
			publicPath: process.env.BASE_URL,
			resultsMaxHeight: "none",
			resultsWrapOffsetY: 0,
			searchClient,
			thumbnailPrefix: "https://findyournextdrink.com/images/",
		};
	},

	computed: {
		...mapGetters(["getUser"]),
	},

	methods: {
		loadDefaultImage(event) {
			event.target.src = this.defaultImage;
			event.target.alt = "Missing image";
		},

		searchHasFocus(state) {
			store.dispatch("setSearchMenuState", state);
		},

		// TODO: more reliably calculate position of box (dispense with hard-coded values) recalc on viewport orientation change
		setSearchResultsMaxHeight() {
			this.resultsWrapOffsetY =
				this.$refs["resultsListWrap"].getBoundingClientRect().y;
			this.resultsMaxHeight =
				window.innerHeight - this.resultsWrapOffsetY - 89 - 16 + "px"; // 89 = height of search bar (50) and gap; 16 = bottom gutter
		},
	},

	mounted() {
		this.setSearchResultsMaxHeight();
	},
};
</script>

<style lang="scss">
$ui-light: #edf4f9;
$ui-text: #2e282a;
$ui-accent: #ef3e36;
$ui-highlight: #f49d37;
$ui-blue: #3470A2;
$borderRadius: 4px;

// Algolia instant search
.ais-InstantSearch {
	position: relative;

	// white fade gradient over bottom of list – TODO: only show when there are results going beyond bottom of list
	// &::after {
	// 	background: url("../assets/images/results-gradient.png") repeat-x;
	// 	bottom: 0;
	// 	content: "";
	// 	height: 48px;
	// 	left: 0;
	// 	position: absolute;
	// 	right: 0;
	// 	z-index: 10;
	// }

	&.has-border .ais-SearchBox-input {
		border: 1px solid #3470a2;
		box-shadow: 4px 4px 0 transparentize($color: #3470a2, $amount: 0.85);
	}

	button {
		background: none;
		border: 0;
		height: 50px;
		padding: 13px;
		position: absolute;
		top: 0;

		&[type="reset"] {
			right: 0;

			svg {
				height: 12px;
				width: 12px;
			}
		}

		&[type="submit"] {
			left: 0;
		}

		svg {
			height: 24px;
			width: 24px;
		}
	}

	input {
		border: 0;
		border-radius: $borderRadius;
		font: inherit;
		height: 50px;
		padding-left: 50px;
		width: 100%;
	}
}

.search-stats {
	background: $ui-light;
	border-radius: $borderRadius $borderRadius 0 0;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	justify-content: space-between;
	margin: 8px 0 0;
	opacity: 1;
	padding: 8px;
	position: relative;
	text-align: left;
	z-index: 10;

	&.no-results {
		justify-content: center;
		border-radius: $borderRadius;
	}
}

.ais-Stats-text {
	display: none;
}

.ais-InfiniteHits {
	background: white;
	border-radius: 0 0 $borderRadius $borderRadius;
	box-shadow: 4px 4px 10px $ui-text;
	overflow: scroll;
	position: relative;
	z-index: 9;

	@media screen and (min-width: 769px) {
		box-shadow: 4px 4px 10px transparentize($ui-text, 0.7);
	}

	article {
		position: relative;
	}

	article.is-favorite {
		&::before {
			background: url("../assets/images/favorite.svg");
			background-size: cover;
			content: "";
			height: 16px;
			left: 40px;
			position: absolute;
			top: 0px;
			width: 16px;
		}

		img {
			border-top-right-radius: 8px;
		}
	}

	article a {
		align-items: center;
		border-bottom: 1px dashed transparentize($color: $ui-text, $amount: 0.8);
		color: inherit;
		display: flex;
		padding: 8px 0;
		text-decoration: none;
	}

	button {
		position: static;
	}

	header {
		color: inherit;
		margin-left: 10px;
		text-align: left;
		white-space: nowrap;
		width: calc(100% - 58px);
	}

	h3 {
		margin: 0;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	img {
		border-radius: $borderRadius;
	}

	li {
		padding: 0 8px;

		&:hover {
			background: $ui-light;
		}

		&:last-child article a {
			border-bottom: 0;
		}

		// applies a different border to the result directly before a "load more" button
		// &:nth-last-child(2) article a {
		// 	border-bottom-style: solid;
		// }

		&:only-child button {
			display: none;
		}
	}

	li.show-more-results {
		&:hover {
			background: white;
		}
		
		button {
			background: $ui-light;
			border: 1px solid transparentize($color: $ui-blue, $amount: 0.5);
			border-radius: $borderRadius;
			color: $ui-blue;
			font: 500 12px "Rubik", sans-serif;
			height: 40px;
			margin: 8px 0;
			padding: 0;
			text-align: center;
			transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
			width: 100%;

			&:hover {
				box-shadow: 1px 1px 4px transparentize($color: #2e282a, $amount: 0.75);
				transform: translate3d(-1px, -1px, 0);
			}
		}
	}

	mark {
		background: $ui-highlight;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.source-ingreds {
		display: block;
		font-size: 12px;
		opacity: 0.75;
		overflow: hidden;
		text-overflow: ellipsis;

		> span:first-child {
			font-weight: 400;
		}
	}

	.ingreds .ais-Highlight {
		&::after {
			content: ", ";
		}

		&:last-child::after {
			content: "";
		}
	}
}
</style>