<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title v-if="title">{{ title }}</title>
		<path
			:d="fillPath"
			:fill="fill"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		fill: {
			type: String,
			default: '#2E282A'
		},
		isFilled: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: null
		}
	},

	computed: {
		fillPath() {
			// filled star
			if (this.isFilled) {
				return 'M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.90001 20.89C6.7344 20.977 6.54768 21.0159 6.36108 21.0022C6.17448 20.9884 5.99548 20.9226 5.84444 20.8122C5.69339 20.7017 5.57635 20.5511 5.50662 20.3775C5.4369 20.2039 5.41728 20.0142 5.45001 19.83L6.45001 14.2L2.33001 10.2C2.20146 10.0717 2.11028 9.91083 2.06623 9.73465C2.02219 9.55848 2.02695 9.37363 2.08001 9.19996C2.13797 9.02223 2.24458 8.86431 2.38776 8.74412C2.53093 8.62392 2.70493 8.54627 2.89001 8.51996L8.59001 7.68996L11.1 2.55996C11.1819 2.39089 11.3097 2.2483 11.4689 2.14853C11.6281 2.04876 11.8121 1.99585 12 1.99585C12.1879 1.99585 12.3719 2.04876 12.5311 2.14853C12.6903 2.2483 12.8181 2.39089 12.9 2.55996L15.44 7.67996L21.14 8.50996C21.3251 8.53627 21.4991 8.61392 21.6423 8.73412C21.7854 8.85431 21.892 9.01223 21.95 9.18996C22.0031 9.36363 22.0078 9.54848 21.9638 9.72465C21.9197 9.90083 21.8286 10.0617 21.7 10.19L17.58 14.19L18.58 19.82C18.6157 20.0074 18.597 20.2012 18.5261 20.3784C18.4553 20.5556 18.3352 20.7088 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z'
			}

			// outlined star
			return 'M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.90001 20.89C6.7344 20.977 6.54768 21.0159 6.36108 21.0022C6.17448 20.9884 5.99548 20.9226 5.84444 20.8122C5.69339 20.7017 5.57635 20.5511 5.50662 20.3775C5.4369 20.2039 5.41728 20.0142 5.45001 19.83L6.45001 14.2L2.33001 10.2C2.20146 10.0717 2.11028 9.91083 2.06623 9.73465C2.02219 9.55848 2.02695 9.37363 2.08001 9.19996C2.13797 9.02223 2.24458 8.86431 2.38776 8.74412C2.53093 8.62392 2.70493 8.54627 2.89001 8.51996L8.59001 7.68996L11.1 2.55996C11.1819 2.39089 11.3097 2.2483 11.4689 2.14853C11.6281 2.04876 11.8121 1.99585 12 1.99585C12.1879 1.99585 12.3719 2.04876 12.5311 2.14853C12.6903 2.2483 12.8181 2.39089 12.9 2.55996L15.44 7.67996L21.14 8.50996C21.3251 8.53627 21.4991 8.61392 21.6423 8.73412C21.7854 8.85431 21.892 9.01223 21.95 9.18996C22.0031 9.36363 22.0078 9.54848 21.9638 9.72465C21.9197 9.90083 21.8286 10.0617 21.7 10.19L17.58 14.19L18.58 19.82C18.6157 20.0074 18.597 20.2012 18.5261 20.3784C18.4553 20.5556 18.3352 20.7088 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21ZM12 16.1C12.1603 16.0959 12.3189 16.1338 12.46 16.21L16.23 18.21L15.51 14C15.4821 13.8392 15.4939 13.6741 15.5444 13.5189C15.595 13.3638 15.6827 13.2234 15.8 13.11L18.8 10.18L14.6 9.55996C14.446 9.52895 14.3014 9.46209 14.1781 9.3648C14.0547 9.2675 13.956 9.14251 13.89 8.99996L12 5.24996L10.11 8.99996C10.0377 9.14366 9.9317 9.26779 9.80113 9.3618C9.67056 9.45581 9.51924 9.51692 9.36001 9.53996L5.16001 10.16L8.16001 13.09C8.27728 13.2034 8.36502 13.3438 8.41557 13.4989C8.46612 13.6541 8.47794 13.8192 8.45001 13.98L7.73001 18.14L11.5 16.14C11.6598 16.0806 11.8329 16.0667 12 16.1V16.1Z'
		}
	},
};
</script>

<style lang="scss" scoped>
</style>