<template>
	<div 
		class="recipe__rating" 
		:class="[
			{
				'has-value': value
			},
			cssClass,
		]"
	>
		<label>
			<RecipeRatingStar :fill="fill" :isFilled="fillRatingStars(1)" title="Rating: Poor" />
			<input
				name="rating"
				type="radio"
				value="1"
				@input="$emit('input', $event.target.value)"
			/>
		</label>
		<label>
			<RecipeRatingStar :fill="fill" :isFilled="fillRatingStars(2)" title="Rating: Fair" />
			<input
				name="rating"
				type="radio"
				value="2"
				@input="$emit('input', $event.target.value)"
			/>
		</label>
		<label>
			<RecipeRatingStar :fill="fill" :isFilled="fillRatingStars(3)" title="Rating: Average" />
			<input
				name="rating"
				type="radio"
				value="3"
				@input="$emit('input', $event.target.value)"
			/>
		</label>
		<label>
			<RecipeRatingStar :fill="fill" :isFilled="fillRatingStars(4)" title="Rating: Good" />
			<input
				name="rating"
				type="radio"
				value="4"
				@input="$emit('input', $event.target.value)"
			/>
		</label>
		<label>
			<RecipeRatingStar :fill="fill" :isFilled="fillRatingStars(5)" title="Rating: Excellent" />
			<input
				name="rating"
				type="radio"
				value="5"
				@input="$emit('input', $event.target.value)"
			/>
		</label>
	</div>
</template>

<script>
import RecipeRatingStar from '@/components/RecipeRatingStar.vue';

export default {
	components: {
		RecipeRatingStar,
	},

	methods: {
		fillRatingStars(rating) {
			return rating <= this.value;
		},
	},

	props: {
		cssClass: {
			type: String
		},
		fill: {
			type: String
		},
		value: {
			type: [Number, String],
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.recipe__rating {
	display: flex;

	&.has-value {
		span {
			opacity: 1;
		}
	}

	input {
		display: none;
	}

	label {
		margin-bottom: 0;
	}

	&.small {
		label,
		svg {
			height: 16px;
			width: 16px;
		}
	}
}
</style>