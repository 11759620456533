<template>
	<div class="wrap">
		<header>
			<h1>
				Privacy Policy
			</h1>
		</header>
		<main class="text-md">
			<p>
				This app is a bit too small to merit a full-fledged, dry-as-dust privacy policy, so for now just know that the only information of yours that I have access to is your Google email address, which you provide if and when you sign into the app (signing in with Google automatically registers an account to the Google email address you choose).
			</p>
			<p>
				The only thing I ever intend on doing with your email is adding it to a mailing list that I <em>might</em> create in the future to promote the app and keep you apprised of feature enhancements. <strong>By creating an account on this app, you consent to allowing your email to be used in this way.</strong>
			</p>
			<p>
				<strong>I will never share your email address with anyone.</strong> If I ever <em>do</em> get around to creating a mailing list for the purposes outlined above, I will provide you with an unsubscribe (opt-out) link.
			</p>
		</main>
	</div>
</template>

<script>

export default {
	metaInfo() {
		return {
			meta: [
				{
					vmid: "description",
					name: "description",
					content:
						"This privacy policy explains what personal information this app collects and how it is used. By using this app, you are consenting to your information being collected and used as described in this policy.",
				},
			],
			title: "Privacy Policy",
		};
	},

	mounted () {
		document.dispatchEvent(new Event('page-ready-for-prerender'));
	},
}
</script>

<style lang="scss" scoped>

</style>