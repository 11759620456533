import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		navMenuHasFocus: false,
		searchMenuHasFocus: false,
		user: {
			loggedIn: false,
			data: null
		}
	},

	getters: {
		getNavMenuFocus(state) {
			return state.navMenuHasFocus
		},

		getSearchMenuFocus(state) {
			return state.searchMenuHasFocus
		},

		getUser(state) {
			return state.user
		}
	},

	mutations: {
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value
		},

		SET_NAV_MENU_FOCUS(state, value) {
			state.navMenuHasFocus = value
		},

		SET_SEARCH_MENU_FOCUS(state, value) {
			state.searchMenuHasFocus = value
		},

		SET_USER(state, data) {
			state.user.data = data
		}
	},

	actions: {
		fetchUser({ commit }, user) {
			commit("SET_LOGGED_IN", user !== null);

			if (user) {
				commit("SET_USER", {
					userId: user.uid,
					displayName: user.displayName,
					email: user.email,
					photoUrl: user.photoURL
				});
			} else {
				commit("SET_USER", null);
			}
		},

		setNavMenuState({ commit }, state) {
			commit("SET_NAV_MENU_FOCUS", state)
		},

		setSearchMenuState({ commit }, state) {
			commit("SET_SEARCH_MENU_FOCUS", state)
		}
	}
});