var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-instant-search',{class:{ 'has-border': _vm.hasBorder },attrs:{"search-client":_vm.searchClient,"index-name":"Cocktails"}},[_c('ais-search-box',{attrs:{"placeholder":"Find by name, spirit, ingredients, and more"}}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.results.hits;
var query = ref.state.query;
return [_c('ais-stats',{directives:[{name:"show",rawName:"v-show",value:(query.length),expression:"query.length"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var nbHits = ref.nbHits;
return (nbHits > 1)?_c('p',{staticClass:"search-stats"},[_c('span',[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" matching recipes ")]),_c('img',{attrs:{"alt":"Search powered by Algolia","src":require("@/assets/images/algolia-search.svg"),"height":"14","width":"98"}})]):(nbHits === 1)?_c('p',{staticClass:"search-stats"},[_c('span',[_c('strong',[_vm._v(_vm._s(nbHits))]),_vm._v(" matching recipe ")]),_c('img',{attrs:{"alt":"Search powered by Algolia","src":require("@/assets/images/algolia-search.svg"),"height":"14","width":"98"}})]):_vm._e()}}],null,true)}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(query.length && !hits.length),expression:"query.length && !hits.length"}],staticClass:"search-stats no-results"},[_vm._v(" 🥺 I couldn’t track that down for you 😭 ")])]}}])}),_c('div',{ref:"resultsListWrap"},[_c('ais-infinite-hits',{style:({ maxHeight: _vm.resultsMaxHeight }),scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var items = ref.items;
				var refineNext = ref.refineNext;
				var isLastPage = ref.isLastPage;
return [_c('ul',[_vm._l((items),function(item){return _c('li',{key:item.objectID},[_c('article',{class:{
								'is-favorite':
									_vm.getUser.loggedIn &&
									'favoritedBy' in item &&
									item.favoritedBy.includes(_vm.getUser.data.userId),
							}},[_c('router-link',{attrs:{"to":'/recipe/' + item.objectID}},[_c('img',{attrs:{"src":(_vm.publicPath + "images/" + (item.thumbnail)),"height":"48","width":"48"},on:{"error":_vm.loadDefaultImage}}),_c('header',[_c('h3',[_c('ais-highlight',{attrs:{"hit":item,"attribute":"name"}})],1),_c('div',{staticClass:"source-ingreds"},[_c('ais-highlight',{attrs:{"hit":item,"attribute":"source"}}),_vm._v(" • "),_c('span',{staticClass:"ingreds"},_vm._l((item.ingredients),function(ingredient,index){return _c('ais-highlight',{key:index,attrs:{"hit":item,"attribute":'ingredients.' + index}})}),1)],1)])])],1)])}),(!isLastPage)?_c('li',{staticClass:"show-more-results"},[_c('button',{on:{"click":refineNext}},[_vm._v(" Show More Results ")])]):_vm._e()],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }