<template>
	<div class="about wrap">
		<header>
			<h1>
				ARTICLE TITLE
			</h1>
		</header>
		<main class="text-md">
			<p>
				Article content...
			</p>
		</main>
	</div>
</template>

<script>

export default {
	metaInfo() {
		return {
			meta: [
				{
					vmid: "description",
					name: "description",
					content:
						"ARTICLE META DESCRIPTION",
				},
			],
			title: "ARTICLE TITLE",
		};
	},

	mounted () {
		document.dispatchEvent(new Event('page-ready-for-prerender'));
	},
}
</script>

<style lang="scss" scoped>

</style>