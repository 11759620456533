import firebase from "firebase/app";
import 'firebase/auth'
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAwhJ0HWwpoK3hkG81KXqtq8RPsQXJO29Y",
	authDomain: "booze-hound-9d5d9.firebaseapp.com",
	projectId: "booze-hound-9d5d9",
	storageBucket: "booze-hound-9d5d9.appspot.com",
	messagingSenderId: "599976476521",
	appId: "1:599976476521:web:69e8d3ac92d7083de047f8"
};

firebase.initializeApp(firebaseConfig);

// firebase.auth().onAuthStateChanged(user => {
// 	console.log(user)
// 	store.dispatch("fetchUser", user);
// });

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged(user => {
			unsubscribe();
			resolve(user);
		}, reject);
	})
};

const FIREBASE = firebase
const FB = firebase.firestore
const AUTH = firebase.auth()
const DB = firebase.firestore()

export {
	FIREBASE,
	FB,
	AUTH,
	DB
}

// export const DB = firebase.initializeApp(firebaseConfig).firestore();