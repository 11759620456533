<template>
	<div class="wrap">
		<header>
			<h1>
				Your Stats
			</h1>
		</header>
		<main class="text-md">
			<p style="text-align: center">
				This feature will come in a later version of the app.
			</p>
		</main>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	metaInfo() {
		return {
			title: "Your Stats",
		};
	},

	computed: {
		...mapGetters(['getUser']),

		isSignedIn() {
			return this.getUser.loggedIn
		}
	},

	watch: {
		isSignedIn(newValue, oldValue) {
			if (newValue === false) {
				this.$router.push({ path: '/' })
			}
		}
	},
}
</script>

<style lang="scss" scoped>

</style>